import { useAppStore } from '#/stores/appStore'

export default defineNuxtRouteMiddleware((to) => {
  const { auth, setAuth } = useAppStore()

  if (auth.expiresIn) {
    const expiresIn = new Date(auth.expiresIn)
    const now = new Date()

    if (now > expiresIn) {
      setAuth('', '')
      return abortNavigation(
        createError({ statusCode: 401, message: 'Unauthorized' }),
      )
    }
  }

  if (to.name === 'auth-index-login' && !!auth.token) {
    return navigateTo({ name: 'app-index-dashboard' })
  }

  if (to.name === 'app-index-dashboard' && !auth.token) {
    return abortNavigation(
      createError({ statusCode: 401, message: 'Unauthorized' }),
    )
  }
})
